import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const Featuresmain = () => {
  const {
    Features: {
      frontmatter: { title, discription, features },
    },
  } = useStaticQuery(graphql`
    query featuresmain {
      Features: markdownRemark(fileAbsolutePath: { regex: "/features_main/" }) {
        frontmatter {
          title: title
          discription: discription
          features: featuresmain {
            title
            discription
            icons {
              path
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className="bg-white" id="homefeatures">
      <div className="max-w-6xl mx-auto py-12 px-5 md:px-8">
        <div className="text-center md:px-24">
          <h2 className="text-2xl md:text-4xl font-bold">{title}</h2>
          <p className="text-textcolor text-lg md:text-xl mt-12">
            {discription}
          </p>
        </div>
        <div className="flex w-full flex-wrap justify-between mt-20">
          {features.map((feature) => {
            const { icons, title, discription } = feature;

            return (
              <div className="w-full md:w-1/4 mb-8 md:mb-0">
                <div className="flex w-3/5 md:w-full mx-auto lg:mx-0 lg:w-3/4 justify-between">
                  {icons.map(({ icon, path }) => {
                    return (
                      <Link className="w-full" to={path}>
                        <img
                          alt={`${title}-icon`}
                          style={{ width: "55px" }}
                          src={icon.publicURL}
                        />
                      </Link>
                    );
                  })}
                </div>
                <div className="text-lg font-bold mt-8 text-center md:text-left">
                  {title}
                </div>
                <div className="mt-4 md:mt-8 text-textcolor text-md text-center md:text-left">
                  {discription}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-3/4 mx-auto flex justify-center mt-8 md:mt-16">
          <Link
            to="/features"
            className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 md:mr-12"
          >
            <button className="bg-theme text-sm text-white btn-purple-on-white rounded-md w-full py-3 outline-none">
              See all features
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Featuresmain;
