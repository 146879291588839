import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link, animateScroll as scroll } from "react-scroll";
const MainHeader = () => {
  const {
    MainHeader: {
      frontmatter: { title, discription },
    },
  } = useStaticQuery(graphql`
    query MainHeader {
      MainHeader: markdownRemark(fileAbsolutePath: { regex: "/main_header/" }) {
        frontmatter {
          title: title
          discription: discription
        }
      }
    }
  `);
  return (
    <div className="h-screen w-full main-header bg-left md:bg-center px-5 md:px-8">
      <div className="max-w-6xl mx-auto flex items-center h-full">
        <div className="w-full md:w-3/5 lg:4/5 xl:w-1/2 ">
          <h1 className=" text-4xl md:text-5xl lg:text-6xl text-white font-bold">
            {title}
          </h1>
          <p className="mt-8 text-lg md:text-xl text-headertext">
            {discription}
          </p>
          <div className="mt-12 flex">
            <Link
              to="homefeatures"
              spy={true}
              smooth={true}
              duration={1100}
              className="w-1/3 lg:w-1/4 mr-12"
            >
              <button className="bg-white text-sm border border-white text-theme  py-3 w-full  outline-none btn-purple-on-purple">
                Learn more
              </button>
            </Link>
            <Link
              to="homepackages"
              spy={true}
              smooth={true}
              duration={1100}
              className=" w-1/3 lg:w-1/4"
            >
              <button className="bg-transparent text-sm border border-white text-white w-full  py-3 outline-none btn-purple-on-white">
                View Packages
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
