import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const Comparision = () => {
  const [isopen, setopen] = useState(false);
  const [iseditor, seteditor] = useState(true);
  // const [isotherfeatures, setotherfeatures] = useState(false);
  const {
    Comparision: {
      frontmatter: {
        merlinwithcauldron,

        packages,
        otherfeatures,
      },
    },
  } = useStaticQuery(graphql`
    query Comparision {
      Comparision: markdownRemark(
        fileAbsolutePath: { regex: "/comparision/" }
      ) {
        frontmatter {
          merlinwithcauldron {
            publicURL
          }

          packages {
            name
            path
            price
            logo {
              publicURL
            }
            buttontext
            theme
            featurekey
            features {
              name
              circles {
                green
                blue
                pink
              }
            }
          }
          otherfeatures {
            title
            essential {
              circles {
                green
                blue
                pink
              }
            }
            professional {
              circles {
                green
                blue
                pink
              }
            }
            commercial {
              circles {
                green
                blue
                pink
              }
            }
          }
        }
      }
    }
  `);
  return (
    //Desktop Version of Comparision table
    <div id="homepackages">
      <div className="bg-comparision py-12 px-5 hidden lg:block">
        <div className="max-w-6xl mx-auto mt-20 py-12 comparision-main bg-white">
          <div className="flex justify-between px-16">
            <div className="w-1/5 ">
              <img
                alt="merlinwithcauldron"
                src={merlinwithcauldron.publicURL}
              ></img>
            </div>
            {packages.map((item) => {
              return (
                <div className="w-1/5 flex flex-col items-center justify-end ">
                  <div className="flex flex-col items-center">
                    <img
                      alt={`${item.buttontext}-logo`}
                      src={item.logo.publicURL}
                    ></img>
                    <span className="mt-4 text-blackish">{item.price}</span>
                    <Link to={item.path} className="py-3 w-full">
                      <button
                        className={`bg-${item.theme} btn-${item.theme}-on-white text-sm   text-white  rounded-md px-8 py-3 outline-none mt-4`}
                      >
                        {item.buttontext}
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex bg-comparision justify-between py-4 mt-12 text-themetext text-sm px-16">
            <div className="w-1/5 ">Feature</div>
            {packages.map((item) => {
              return <div className="w-1/5 text-center">{item.name}</div>;
            })}
          </div>
          <div className="px-16 text-blackish text-xl font-bold mt-8">
            Editor
          </div>
          <div className="flex justify-between  mt-8 text-textcolor text-sm px-16">
            <div className="w-1/5 "></div>
            {packages.map((item) => {
              return (
                <div className="w-1/5 ">
                  <ul>
                    {item.features.map((feature) => {
                      return (
                        <li className="flex justify-between mb-4 pl-6">
                          <span className="pr-6">{feature.name}</span>
                          <div className="flex">
                            {Array.from(
                              { length: feature.circles.green },
                              () => {
                                return <div className="greencircle mr-2"></div>;
                              }
                            )}
                            {Array.from(
                              { length: feature.circles.pink },
                              () => {
                                return <div className="pinkcircle mr-2"></div>;
                              }
                            )}
                            {Array.from(
                              { length: feature.circles.blue },
                              () => {
                                return <div className="bluecircle mr-2"></div>;
                              }
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="mt-24">
            {otherfeatures.map((feature) => {
              return (
                <div className="px-16 flex justify-between items-center text-blackish font-bold mb-6">
                  <div className="w-1/5 text-xl">{feature.title}</div>
                  <div className="w-1/5 flex justify-end">
                    {" "}
                    <div className="flex">
                      {Array.from(
                        { length: feature.essential.circles.green },
                        () => {
                          return <div className="greencircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.essential.circles.pink },
                        () => {
                          return <div className="pinkcircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.essential.circles.blue },
                        () => {
                          return <div className="bluecircle mr-2"></div>;
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-1/5 flex justify-end">
                    <div className="flex">
                      {Array.from(
                        { length: feature.professional.circles.green },
                        () => {
                          return <div className="greencircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.professional.circles.pink },
                        () => {
                          return <div className="pinkcircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.professional.circles.blue },
                        () => {
                          return <div className="bluecircle mr-2"></div>;
                        }
                      )}
                    </div>
                  </div>
                  <div className="w-1/5 flex justify-end">
                    <div className="flex">
                      {Array.from(
                        { length: feature.commercial.circles.green },
                        () => {
                          return <div className="greencircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.commercial.circles.pink },
                        () => {
                          return <div className="pinkcircle mr-2"></div>;
                        }
                      )}
                      {Array.from(
                        { length: feature.commercial.circles.blue },
                        () => {
                          return <div className="bluecircle mr-2"></div>;
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* mobile version */}
      <div className="block lg:hidden bg-comparision pt-12 px-5 md:px-8 w-full ">
        <div className="bg-white pt-8 px-5 md:px-8 flex flex-col comparison-mobile">
          <div className="flex justify-center w-full">
            <div className="w-2/3 flex justify-center">
              <img
                alt="merlinwithcauldron"
                src={merlinwithcauldron.publicURL}
              ></img>
            </div>
          </div>
          <span className="mt-8 text-xl text-theme">Feature</span>
          <div className=" mt-4 border border-borders rounded-md p-3 ">
            <div
              onClick={() => setopen(!isopen)}
              className="flex justify-between"
            >
              <p className="pr-1 text-theme text-lg ">
                {iseditor ? "Editor" : "Other Features"}
              </p>
              <p>▾</p>
            </div>
            <div
              className={`w-full mt-4 flex flex-col ${isopen ? "" : "hidden"}`}
            >
              <span
                className="text-theme text-lg mb-2 cursor-pointer"
                onClick={() => {
                  seteditor(true);
                  // setotherfeatures(false);
                  setopen(false);
                }}
              >
                Editor
              </span>
              <span
                className="text-theme text-lg cursor-pointer"
                onClick={() => {
                  // setotherfeatures(true);
                  seteditor(false);
                  setopen(false);
                }}
              >
                Other Features
              </span>
            </div>
          </div>

          <div className="mt-12">
            {packages.map((item) => {
              return (
                <div className="flex flex-col mb-12">
                  <img
                    alt={`${item.name}-logo`}
                    src={item.logo.publicURL}
                  ></img>
                  <span className="mt-4 text-blackish text-center">
                    {item.price}
                  </span>
                  <span className="text-theme text-xl my-4 text-center">
                    {item.name}
                  </span>
                  {iseditor ? (
                    <ul>
                      {item.features.map((feature) => {
                        return (
                          <li className="flex justify-between mb-2">
                            <span className="text-md text-textcolor">
                              {feature.name}
                            </span>
                            <div className="flex">
                              {Array.from(
                                { length: feature.circles.green },
                                () => {
                                  return (
                                    <div className="greencircle mr-2"></div>
                                  );
                                }
                              )}
                              {Array.from(
                                { length: feature.circles.pink },
                                () => {
                                  return (
                                    <div className="pinkcircle mr-2"></div>
                                  );
                                }
                              )}
                              {Array.from(
                                { length: feature.circles.blue },
                                () => {
                                  return (
                                    <div className="bluecircle mr-2"></div>
                                  );
                                }
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="mt-6">
                      {otherfeatures.map((feature) => {
                        let features = { ...feature };
                        let { circles } = features[item.featurekey];

                        return (
                          <div className="w-full flex justify-between text-md text-textcolor mb-2">
                            {circles.green === 0 &&
                            circles.pink === 0 &&
                            circles.blue === 0 ? (
                              ""
                            ) : (
                              <span>{features.title}</span>
                            )}

                            <div className="flex">
                              {Array.from({ length: circles.green }, () => {
                                return <div className="greencircle mr-2"></div>;
                              })}
                              {Array.from({ length: circles.pink }, () => {
                                return <div className="pinkcircle mr-2"></div>;
                              })}
                              {Array.from({ length: circles.blue }, () => {
                                return <div className="bluecircle mr-2"></div>;
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comparision;
