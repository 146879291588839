import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Tools = () => {
  const {
    tools: {
      frontmatter: { title, discription, tools },
    },
  } = useStaticQuery(graphql`
    query tools {
      tools: markdownRemark(fileAbsolutePath: { regex: "/tools.md/" }) {
        frontmatter {
          title
          discription
          tools {
            title
            discription
            image: tool_image {
              publicURL
            }
          }
        }
      }
    }
  `);
  return (
    <div className="bg-comparision">
      <div className="max-w-6xl mx-auto lg:mt-20 py-12  px-5 md:px-8">
        <div className="text-center lg:px-24">
          <h2 className="text-2xl lg:text-4xl text-blackish font-bold">
            {title}
          </h2>
          <p className="text-textcolor text-lg lg:text-xl mt-12">
            {discription}
          </p>
        </div>
        <div className="w-full mt-24">
          {tools.map((edge, index) => {
            const { title, discription, image } = edge;
            if (index % 2 === 0) {
              return (
                <div className="w-full flex items-center flex-wrap-reverse mb-24">
                  <div className="w-full lg:w-1/2">
                    <img alt={`${title}`} src={image.publicURL} />
                  </div>
                  <div className="w-full lg:w-1/2  lg:px-16 xl:px-32 mb-8 lg:mb-0">
                    <div className="text-2xl lg:text-3xl font-bold text-blackish">
                      {title}
                    </div>
                    <div className="mt-8 text-textcolor text-md">
                      {discription}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="w-full flex-wrap flex items-center mb-24">
                  <div className="w-full lg:w-1/2 lg:px-16 xl:px-32 mb-8 lg:mb-0">
                    <div className="text-2xl lg:text-3xl font-bold text-blackish">
                      {title}
                    </div>
                    <div className="mt-8 text-textcolor text-md">
                      {discription}
                    </div>
                  </div>

                  <div className="w-full lg:w-1/2 ">
                    <img alt={`${title}-preview`} src={image.publicURL} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Tools;
