import React from "react";
import MainHeader from "../views/homepage/mainheader";
import "../styles/style.scss";
import Layout from "../components/layout";
import Featuresmain from "../views/homepage/featuresmain";
import Tools from "../views/homepage/tools";
import Comparision from "../views/homepage/comparision";
import Includes from "../views/homepage/includes";
import Reviews from "../views/homepage/reviews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Portfolio from "../views/homepage/portfolio";
import SEO from "../components/seo";
const App = () => (
  <Layout home={true}>
    <SEO
      seo={{
        title: "Home",
        description:
          "Manage every aspect of your company’s online presence in one easy to use dashboard.",
        previewURL: "https://merlinpanel.com/images/home/preview.png",
      }}
    />
    <MainHeader />
    <Featuresmain />
    <Tools />
    <Portfolio />
    <Comparision />
    <Includes />
    <Reviews />
  </Layout>
);

export default App;
