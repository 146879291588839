import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { useStaticQuery } from "gatsby";
import moment from "moment";
const Reviews = () => {
  const {
    Reviews: { edges },
  } = useStaticQuery(graphql`
    query Reviews {
      Reviews: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/reviews/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              id: review_id
              title: review_title
              review: review_review
              name: review_name
              package_name: review_package
              date: review_date
              owner: review_owner
              icon: review_icon {
                publicURL
              }
              logo: review_logo {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const settings = {
    infinite: true,
    arrows: false,
    dots: false,
    centerMode: true,
    centerPadding: "25%",
    slidesToShow: 1,
    speed: 1500,
    noSwiping: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          spaceBetween: 10,
        },
      },
    ],
  };
  const slider = useRef(null);
  const next = () => {
    setclass("lostopacity");
    setTimeout(() => {
      setclass("fullopacity");
    }, 1000);
    slider.current.slickNext();
  };
  const previous = () => {
    setclass("lostopacity");
    setTimeout(() => {
      setclass("fullopacity");
    }, 1000);
    slider.current.slickPrev();
  };
  const [currentclass, setclass] = useState("fullopacity");

  return (
    <div className="py-12 md:py-24 relative px-5 md:px-8" id="reviews">
      <div
        className={`flex review-arrow-previous absolute btn-purple-on-white  text-3xl z-10 text-white items-center justify-center cursor-pointer ${currentclass}`}
        onClick={() => {
          previous();
        }}
      >
        <img
          alt="arrow-previous"
          src={require("../../media/angle-left.svg")}
        ></img>
      </div>
      <div
        className={` flex  review-arrow-next absolute btn-purple-on-white  text-3xl z-10 text-white items-center justify-center cursor-pointer ${currentclass}`}
        onClick={() => {
          next();
        }}
      >
        <img
          alt="arrow-next"
          src={require("../../media/angle-right.svg")}
        ></img>
      </div>
      <Slider {...settings} ref={slider}>
        {edges.map((edge, index) => {
          const {
            title,
            review,

            date,
            name,
            owner,
            logo,
            package_name,
          } = edge.node.frontmatter;
          return (
            <div>
              <div className="flex w-full justify-center py-8 ">
                <div className="w-full md:w-4/5 review-card p-5 md:p-20">
                  <div className="flex flex-col md:flex-row items-center">
                    <div className="review-circle mb-8 md:mb-0 flex items-center justify-center">
                      <img alt={`${name}-logo`} src={logo.publicURL} />
                    </div>
                    <div className="ml-8">
                      <p className="text-textcolor text-xl">{package_name}</p>
                      <div className="text-3xl font-bold text-blackish uppercase">
                        {name}
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 flex">
                    <div className="w-full md:w-2/3">
                      <h3 className="text-lg font-bold text-blackish">
                        {title}
                      </h3>
                      <div className="text-textcolor mt-4 review-section">
                        {review}
                      </div>
                    </div>
                    <div className="hidden md:block md:w-1/3 text-textcolor text-right">
                      {moment(date, "DD MM YYYY").format("Do MMMM YYYY")}
                    </div>
                  </div>
                  <p className="mt-12 text-textcolor">{owner}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Reviews;
