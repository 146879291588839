import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Swiper from "react-id-swiper";
const Portfolio = () => {
  const settings = {
    loop: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    noSwiping: true,
  };
  const {
    portfolio: { edges },
  } = useStaticQuery(graphql`
    query portfolio {
      portfolio: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/portfolio/" } }
        sort: { fields: [frontmatter___portfolio_id], order: ASC }
        limit: 5
      ) {
        edges {
          node {
            frontmatter {
              id: portfolio_id
              title: portfolio_title
              subtitle: portfolio_subtitle
              discription: portfolio_discription
              name: portfolio_name
              package_name: portfolio_package_name
              portfolio_icon {
                publicURL
              }
              image: portfolio_image {
                publicURL
              }
              icon: portfolio_package_icon {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const [mySwiper, setSwiper] = useState(null);
  const [activeIndex, setactiveIndex] = useState(1);

  useEffect(() => {
    if (mySwiper) {
      setactiveIndex(mySwiper.activeIndex);
    }
  }, [mySwiper, setactiveIndex]);

  const handleSlide = (index) => {
    mySwiper.slideTo(index + 1);
    setactiveIndex(index + 1);
  };

  return (
    <div className="max-w-6xl py-12 lg:py-32  mx-auto px-5 md:px-8">
      <div className="flex w-full">
        <Swiper {...settings} getSwiper={setSwiper}>
          {edges.map((edge) => {
            const {
              title,
              discription,
              subtitle,
              image,
              icon,
              package_name,
            } = edge.node.frontmatter;

            return (
              <div className="w-full">
                <div className="w-full flex-wrap-reverse flex items-center">
                  <div className="w-full lg:w-1/2 ">
                    <img alt={`${title}-preview`} src={image.publicURL} />
                  </div>
                  <div className="w-full mb-8 lg:mb-0 lg:w-1/2 lg:px-20">
                    <div className="text-lg lg:text-xl text-textcolor">
                      {subtitle}
                    </div>
                    <h2 className="mt-8 text-2xl lg:text-4xl font-bold text-blackish">
                      {title}
                    </h2>
                    <div className="mt-8 flex items-center">
                      <img
                        alt={`${package_name}-icon`}
                        src={icon.publicURL}
                      ></img>
                      <span className="text-lg text-textcolor ml-2">
                        {package_name}
                      </span>
                    </div>
                    <p className="text-md lg:text-lg text-textcolor mt-8">
                      {discription}
                    </p>
                    <Link to="/features" className="h-full w-full">
                      <button className="bg-theme text-sm text-white btn-purple-on-white rounded-md w-2/5 lg:w-1/2 xl:w-2/5  py-3 mt-8 outline-none">
                        See all features
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>
      </div>
      <div className="py-8 mt-4 lg:md-20 flex flex-wrap justify-center navigation-logos">
        {edges.map((edge, index) => {
          const { name, portfolio_icon } = edge.node.frontmatter;
          return (
            <div
              className={`${
                activeIndex === index + 1
                  ? "opacity-100"
                  : "opacity-25 hover:opacity-100"
              } w-1/3 lg:w-1/5 flex flex-col items-center justify-center mb-4 logo-parent`}
              onClick={() => {
                handleSlide(index);
              }}
            >
              <div
                className={`logo cursor-pointer flex items-center justify-center`}
              >
                <div className="w-full h-full">
                  <img
                    style={{ height: "100%" }}
                    src={portfolio_icon.publicURL}
                    alt={`${name}-icon`}
                  />
                </div>
              </div>
              <div className="text-md lg:text-xl font-bold mt-2 lg:mt-8 text-center h-16">
                {name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
